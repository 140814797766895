import { FieldPath, UseFormRegisterReturn } from 'react-hook-form'
import { Compose, composeThemeFromProps } from '@css-modules-theme/react'
import { Input as BaseInput } from '@overdose/components'

import classNames from 'classnames'
import { usePage } from '~/hooks'
import { Icon } from '../Icon'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import defaultStyles from './Input.module.css'
import { InputProps } from './Input.types'

const Input = <
  TFormValues extends Record<string, unknown> = Record<string, unknown>
>({
  name,
  label,
  register,
  error,
  theme,
  wrapperStyles,
  ...props
}: InputProps<TFormValues>) => {
  const useFormProps = register
    ? register(name as FieldPath<TFormValues>)
    : ({} as UseFormRegisterReturn)

  const styles = composeThemeFromProps(
    defaultStyles,
    { theme: { ...theme, box: 'h-auto' } },
    { compose: Compose.Merge }
  )

  const page = usePage()
  const isRTM = page.brand === 'rtm'

  return (
    <div className={wrapperStyles}>
      {label && (
        <label
          htmlFor={`${name}_input`}
          className='inline-block leading-4 mb-2'>
          <Typography
            tag={TypographyTag.span}
            variant={
              isRTM
                ? TypographyVariant.BodyRegularBold
                : TypographyVariant.BodySmallBold
            }
            className={classNames(
              'inline-block text-primary-heading !font-extrabold',
              {
                [styles.disabledLabel]: props.disabled,
              }
            )}>
            {label}
            {props.required && '*'}
          </Typography>
        </label>
      )}
      <BaseInput
        name={name}
        {...props}
        theme={styles}
        {...useFormProps}
        validationStatus={error ? 'error' : null}
        onChange={(
          _value: string,
          event: React.ChangeEvent<HTMLInputElement>
        ) => {
          // This if block will be executed and call the useFormProps onChange method when the input is wrapped by a Form component.
          if (Object.keys(useFormProps).length > 0) {
            useFormProps.onChange(event)
          }
          // If we specifically pass an onChange prop then this code block will be executed even if the input is not wrapped by a Form component.
          props.onChange?.(_value, event)
        }}
      />
      {error && (
        <div className='flex gap-2 mt-2 items-center'>
          <Icon
            iconName='alert-triangle-filled'
            size={16}
            color='var(--color-state-alert)'
          />
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.BodySmallBold}
            className={styles.validationError}>
            {error}
          </Typography>
        </div>
      )}
    </div>
  )
}

export default Input
