import classNames from 'classnames'
import styles from './SectionContainer.module.css'
import { SectionContainerProps } from './SectionContainer.types'

export const SectionContainer = ({
  tag = 'section',
  width = 'full',
  children,
  wrapperClassName,
  innerClassName,
}: SectionContainerProps) => {
  const Wrapper = tag || 'section'

  return (
    <Wrapper
      className={classNames(
        {
          [styles.full]: width === 'full',
          [styles.wide]: width === 'wide',
          [styles.medium]: width === 'medium',
          [styles.narrow]: width === 'narrow',
        },
        wrapperClassName || ''
      )}>
      <div
        className={classNames(
          {
            [styles.wideInner]: width === 'wide',
            [styles.mediumInner]: width === 'medium',
            [styles.narrowInner]: width === 'narrow',
          },
          innerClassName
        )}>
        {children}
      </div>
    </Wrapper>
  )
}
