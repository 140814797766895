import { forwardRef } from 'react'
// import { Link as BaseLink } from '@overdose/components'
import NextLink from 'next/link'
// eslint-disable-next-line no-restricted-imports

export const Link = forwardRef<
  HTMLAnchorElement,
  React.ComponentProps<typeof NextLink>
>(({ prefetch, ...rest }, ref) => {
  const linkProps: { prefetch?: false } = {}
  if (prefetch === false) {
    linkProps.prefetch = false
  }
  if (!rest.href) {
    return <span className={rest.className}>{rest.children}</span>
  }
  return <NextLink ref={ref} {...linkProps} {...rest} />
})
