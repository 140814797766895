import { IconProps } from './Icon.types'

export const IconMessageCircle = ({
  stroke,
  size,
  color,
  ...rest
}: IconProps) => {
  return (
    <svg
      width={size}
      height={size}
      strokeWidth={stroke}
      viewBox='0 0 18 18'
      fill='none'
      {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M9.2877 3.73999C7.81161 3.68014 6.37087 4.09989 5.23381 4.90658C4.09832 5.71214 3.35114 6.84115 3.10227 8.0707C2.85386 9.29795 3.11252 10.5698 3.84632 11.6551C3.97692 11.8482 4.01026 12.0912 3.93653 12.3123L3.37604 13.9938L5.61893 13.5166C5.7822 13.4819 5.9524 13.5026 6.10256 13.5755C7.38817 14.1996 8.89322 14.3902 10.3331 14.105C11.7728 13.8198 13.0281 13.083 13.8767 12.0535C14.723 11.0268 15.1102 9.77764 14.9886 8.53703C14.867 7.29523 14.2406 6.11563 13.1973 5.22572C12.152 4.33417 10.7638 3.79984 9.2877 3.73999ZM4.36587 3.68319C5.78586 2.67578 7.5563 2.16856 9.34846 2.24122C11.1406 2.31389 12.8553 2.96254 14.1707 4.08447C15.488 5.20805 16.3193 6.73544 16.4815 8.3908C16.6438 10.0474 16.1215 11.6884 15.0342 13.0076C13.9491 14.3239 12.3782 15.229 10.6246 15.5764C8.95852 15.9065 7.21602 15.7161 5.68621 15.0359L2.40609 15.7338C2.14299 15.7897 1.87006 15.7005 1.6908 15.5C1.51155 15.2994 1.45344 15.0182 1.5385 14.763L2.40085 12.176C1.60361 10.8376 1.32584 9.28609 1.63208 7.77311C1.96255 6.14049 2.9443 4.69172 4.36587 3.68319Z'
        fill={color}
      />
      <path
        d='M9 9V9.0075'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M6 9V9.0075'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M12 9V9.0075'
        stroke={color}
        strokeWidth='2'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  )
}
