import React from 'react'

type IProps = {
  children: React.ReactNode
  fill?: string
}

function Squircle({ children, fill }: IProps) {
  return (
    <span className='relative md:w-4 md:h-4 w-5 h-5'>
      <svg
        viewBox='0 0 160 160'
        xmlns='http://www.w3.org/2000/svg'
        className='w-full h-full'
        version='1.1'
        fill={fill}>
        <path
          d='M 0 80 C 0 20, 20 0, 80 0 S 160 20, 160 80, 140 160 80 160, 0 140, 0 80'
          transform='rotate(0,80,80)translate(0,0)'
        />
      </svg>
      <span className='absolute w-full inset-0 flex justify-center items-center text-center'>
        <span className='w-4 h-4 flex justify-center items-center'>
          {children}
        </span>
      </span>
    </span>
  )
}

export default Squircle
