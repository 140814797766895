import {
  IconCoins,
  IconCreditCard,
  IconHelpCircle,
  IconSend,
  IconShieldCheck,
  IconShieldHalfFilled,
  IconShoppingBag,
  IconTruckReturn,
  IconUserCircle,
  IconFileDownload,
  IconBrandFacebook,
  IconBrandYoutube,
  IconBrandInstagram,
  TablerIconsProps,
  IconMapPin,
  IconBuildingStore,
  IconShieldLock,
  IconEdit,
  IconCopy,
  IconClipboardList,
  IconTrash,
  IconBrandHipchat,
  IconLocation,
  IconAlertTriangle,
  IconChevronRight,
  IconCircleCheck,
  IconBrandX,
  IconAddressBook,
  IconMap2,
  IconLoader,
  IconSpeakerphone,
  IconMedal,
  IconWifi,
  IconSearch,
  IconTruckDelivery,
  IconDeviceLaptop,
  IconTrolley,
  IconGiftCard,
} from '@tabler/icons-react'

import { IconProps } from './Icon.types'
import { IconAlertTriangle2 } from './IconAlertTriangleFilled'
import { IconMessageCircle } from './IconMessageCircle'
import { IconShieldCheck2 } from './IconShieldCheck2'

export const TABLER_ICON_MAP: {
  [key: string]: (props: TablerIconsProps) => React.JSX.Element
} = {
  'shield-half-filled': IconShieldHalfFilled,
  'shopping-bag': IconShoppingBag,
  'help-circle': IconHelpCircle,
  'shield-check': IconShieldCheck,
  'user-circle': IconUserCircle,
  'truck-return': IconTruckReturn,
  'credit-card': IconCreditCard,
  'alert-triangle': IconAlertTriangle,
  'chevron-right': IconChevronRight,
  'circle-check': IconCircleCheck,
  send: IconSend,
  coins: IconCoins,
  'alert-triangle-filled': IconAlertTriangle2,
  'message-circle': IconMessageCircle,
  'file-download': IconFileDownload,
  'brand-facebook': IconBrandFacebook,
  'brand-twitter': IconBrandX,
  'brand-youtube': IconBrandYoutube,
  'brand-instagram': IconBrandInstagram,
  'map-pin': IconMapPin,
  'building-store': IconBuildingStore,
  'shield-lock': IconShieldLock,
  edit: IconEdit,
  copy: IconCopy,
  'clipboard-list': IconClipboardList,
  trash: IconTrash,
  'brand-hipchat': IconBrandHipchat,
  location: IconLocation,
  'shield-check-2': IconShieldCheck2,
  'address-book': IconAddressBook,
  'map-2': IconMap2,
  loader: IconLoader,
  'speaker-phone': IconSpeakerphone,
  medal: IconMedal,
  wifi: IconWifi,
  search: IconSearch,
  'truck-delivery': IconTruckDelivery,
  'device-laptop': IconDeviceLaptop,
  trolley: IconTrolley,
  'gift-card': IconGiftCard,
}

const DEFAULT_ICON_SIZE = 72
const DEFAULT_COLOR = 'var(--color-state-success-light)'

export const Icon = ({
  iconName,
  size = DEFAULT_ICON_SIZE,
  color = DEFAULT_COLOR,
  ...rest
}: IconProps) => {
  const Icon = TABLER_ICON_MAP[iconName]
  return <Icon size={size} color={color} {...rest} />
}
