import { IconProps } from './Icon.types'

export const IconAlertTriangle2 = ({
  stroke,
  size,
  color,
  ...rest
}: IconProps) => {
  return (
    <svg width={size} height={size} strokeWidth={stroke} {...rest}>
      <path
        d='M0.666748 14.0002H15.3334L8.00008 1.3335L0.666748 14.0002ZM8.66675 12.0002H7.33341V10.6668H8.66675V12.0002ZM8.66675 9.3335H7.33341V6.66683H8.66675V9.3335Z'
        fill={color}
      />
    </svg>
  )
}
