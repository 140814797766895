import classNames from 'classnames'
import fallbackImage from '~/public/images/fallback.png'
import { Image } from '../Image'
import { ImageProps } from '../Image/Image.types'
import styles from './ProductImage.module.css'

export const ProductImage = (props: ImageProps) => {
  const { src, ...rest } = props
  return (
    <Image
      src={src || fallbackImage?.src}
      {...rest}
      className={classNames(props.className, styles.root)}
    />
  )
}
