import React from 'react'
import classNames from 'classnames'
import { Price } from '~/components/Price'
import { TypographyTag } from '~/components/Typography'
import { ProductPriceProps } from '../ProductCard.types'

export const ProductPrice = ({
  isFeatured,
  finalPrice,
  regularPrice,
  className,
}: ProductPriceProps) => {
  return (
    <div className={classNames('mt-2 lg:mt-4', className)}>
      <Price
        tag={TypographyTag.div}
        finalPrice={finalPrice}
        regularPrice={regularPrice}
        finalPriceClassName={classNames({
          'text-lg leading-6 md:text-2xl md:leading-8': isFeatured,
          'text-sm leading-4 md:text-lg md:leading-6': !isFeatured,
        })}
        regularPriceClassName={classNames({
          'md:text-lg md:leading-6': isFeatured,
          'text-sm leading-4': !isFeatured,
        })}
        savePriceClassName={classNames({
          'text-lg leading-6 md:text-2xl md:leading-8': isFeatured,
          'text-sm leading-4 md:text-lg md:leading-6': !isFeatured,
        })}
      />
    </div>
  )
}
