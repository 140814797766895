import { useId, useState } from 'react'
import { Input } from '@overdose/components'
import { IconMinus, IconPlus } from '@tabler/icons-react'
import classNames from 'classnames'
import { Button } from '../Button'
import styles from './QuantitySelector.module.scss'
import { QuantitySelectorProps } from './QuantitySelector.types'
import Squircle from './Squircle'

function QuantitySelector({
  defaultValue,
  onChange,
  maxValue,
  minValue = 1,
  name = 'quantity',
  className,
  disabled,
}: QuantitySelectorProps) {
  const id = `${name}_${useId()}`
  const [localValue, setLocalValue] = useState<string | number>(defaultValue)
  const isDisabledMinus = !localValue || Number(localValue) <= minValue
  const isDisabledPlus = !!maxValue && Number(localValue || 0) >= maxValue

  const handleControlButton = (sign: string, e?: MouseEvent) => {
    e?.stopPropagation()
    e?.preventDefault()

    const delta = sign === 'plus' ? 1 : -1
    const v = Number(localValue || 0) + delta
    if (v < minValue) {
      return
    }

    setLocalValue(v)
    onChange?.(v)
  }

  const handleChange = (v: string) => {
    if (!v) {
      setLocalValue('')
      return
    }

    if (!/^\d+$/.test(v)) {
      return
    }

    const val = Number(v)
    setLocalValue(val)
    onChange?.(val)
  }

  const handleBlurInput = () => {
    if (!localValue) {
      setLocalValue(minValue)
      onChange?.(minValue)
    }
  }

  const handleKeydownInput = (e: KeyboardEvent) => {
    e.stopPropagation()

    const key = e.key
    if (key === 'ArrowDown') {
      handleControlButton('minus')
    } else if (key === 'ArrowUp') {
      handleControlButton('plus')
    }
  }

  return (
    <div
      className={classNames(styles.root, className, 'md:w-auto block mb-1', {
        '!cursor-not-allowed': disabled,
      })}>
      <Button
        variant='ghost'
        className={styles.ctrl_btn}
        disabled={isDisabledMinus || disabled}
        onClick={(e: MouseEvent) => {
          return handleControlButton('minus', e)
        }}>
        <Squircle>
          <IconMinus color='white' className='!w-2/3 !h-2/3' />
        </Squircle>
      </Button>
      <Input
        name={id}
        theme={{ input: styles.input }}
        defaultValue={defaultValue}
        value={localValue}
        onBlur={handleBlurInput}
        onKeyDown={handleKeydownInput}
        onChange={handleChange}
        disabled={disabled}
      />
      <Button
        variant='ghost'
        className={styles.ctrl_btn}
        disabled={isDisabledPlus || disabled}
        onClick={(e: MouseEvent) => {
          return handleControlButton('plus', e)
        }}>
        <Squircle>
          <IconPlus color='white' className='!w-2/3 !h-2/3' />
        </Squircle>
      </Button>
    </div>
  )
}

export default QuantitySelector
