import { IconProps } from './Icon.types'

export const IconShieldCheck2 = ({
  stroke,
  size,
  color,
  ...rest
}: IconProps) => {
  return (
    <svg width={size} height={size} strokeWidth={stroke} {...rest}>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M47.1213 27.8787C48.2929 29.0503 48.2929 30.9497 47.1213 32.1213L35.1213 44.1213C33.9497 45.2929 32.0503 45.2929 30.8787 44.1213L24.8787 38.1213C23.7071 36.9497 23.7071 35.0503 24.8787 33.8787C26.0503 32.7071 27.9497 32.7071 29.1213 33.8787L33 37.7574L42.8787 27.8787C44.0503 26.7071 45.9497 26.7071 47.1213 27.8787Z'
        fill={color}
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M34.0126 6.75314C35.1476 5.74895 36.8533 5.74895 37.9883 6.75314C44.4119 12.4362 52.7956 15.3952 61.3633 15.0031C62.7439 14.94 63.9889 15.828 64.3787 17.1539C65.8529 22.1687 66.3039 27.4287 65.7051 32.6212C65.1063 37.8138 63.4697 42.833 60.8928 47.3806C58.3158 51.9282 54.851 55.9114 50.7042 59.0935C46.5574 62.2755 41.8133 64.5916 36.7537 65.9039C36.2597 66.032 35.7412 66.032 35.2473 65.9039C30.1877 64.5916 25.4436 62.2755 21.2968 59.0935C17.15 55.9114 13.6852 51.9282 11.1082 47.3806C8.53123 42.833 6.8947 37.8138 6.29586 32.6212C5.69702 27.4287 6.14809 22.1687 7.62226 17.1539C8.01202 15.828 9.25709 14.94 10.6376 15.0031C19.2053 15.3952 27.5891 12.4362 34.0126 6.75314ZM12.8166 21.0319C12.027 24.6045 11.836 28.2888 12.2564 31.9338C12.7631 36.3276 14.1478 40.5746 16.3283 44.4226C18.5088 48.2705 21.4406 51.6409 24.9494 54.3334C28.2517 56.8674 32.0017 58.7524 36.0005 59.8912C39.9993 58.7524 43.7492 56.8674 47.0515 54.3334C50.5603 51.6409 53.4921 48.2705 55.6726 44.4226C57.8531 40.5746 59.2379 36.3276 59.7446 31.9338C60.165 28.2888 59.9739 24.6045 59.1844 21.0319C50.7812 20.8878 42.6475 18.0311 36.0005 12.8917C29.3535 18.0311 21.2197 20.8878 12.8166 21.0319Z'
        fill={color}
      />
    </svg>
  )
}
