import React from 'react'
import { Link } from '@overdose/components'
import { IconChevronRight } from '@tabler/icons-react'
import classNames from 'classnames'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'

import styles from './Card.module.css'
import { CardProps } from './Card.types'

function Card({
  icon,
  title,
  variant = 'default',
  action,
  children,
  dataId,
}: CardProps) {
  return (
    <div
      className={classNames([
        'card p-4 rounded-md relative',
        styles.wrapper,
        styles[variant],
        action ? 'pr-12' : null,
      ])}>
      {action?.href && (
        <>
          <Link
            data-id={dataId}
            to={action.href || '#'}
            title={action.title || ''}
            className={styles.link}
            target={action?.openInNewWindow ? '_blank' : '_self'}
          />
          <span className={styles.linkArrow}>
            <IconChevronRight size={24} />
          </span>
        </>
      )}
      {action?.onClick && !action?.href && (
        <button
          data-id={dataId}
          type='button'
          className='absolute inset-0 bg-transparent border-0 outline-0 z-10'
          onClick={action.onClick}>
          <span className={styles.linkArrow}>
            <IconChevronRight size={24} />
          </span>
        </button>
      )}
      {(icon || title) && (
        <div className='flex items-center gap-2 font-bold'>
          {icon && <div>{icon}</div>}
          {title && (
            <div className={variant === 'info' ? 'text-primary' : ''}>
              <Typography
                tag={TypographyTag.p}
                variant={TypographyVariant.BodyRegularBold}
                className='text-primary-heading'>
                {title}
              </Typography>
            </div>
          )}
        </div>
      )}

      {children && (
        <div
          className={classNames(
            styles.content,
            {
              'mt-2': icon || title,
            },
            'h-full'
          )}>
          {children}
        </div>
      )}
    </div>
  )
}

export default Card
